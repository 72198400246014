import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Layout from "../components/layout"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"
import Swiper, { Autoplay } from "swiper"
import WorksList from "../components/worksList";
import _ from "lodash"
import "../assets/css/swiper-bundle.css"
import { tab01Item } from "./works.module.css"
Swiper.use([Autoplay])


const chunkHelps = (posts, postsPerPage) => {
  return posts.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/postsPerPage);
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, []);
}


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      className="tab01-item"
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}



const WorksPage = () => {
  const data = useStaticQuery(graphql`{
    banner: file(relativePath: {eq: "2xbanner3.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    default: file(relativePath: {eq: "default_works.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 720, quality: 80, layout: CONSTRAINED)
      }
    }
    works: allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "works"}}}}}
      sort: {fields: date, order: DESC}
      limit: 1000
    ) {
      edges {
        node {
          id
          slug
          title
          categories {
            nodes {
              id
              slug
            }
          }
          project {
            worksTitleCn
            worksTitleEn
            worksLocation
            worksCover {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`)

  const works_data = data.works.edges;
  const filterHelps = ( el ) => {
    return _.filter(works_data, function(i) {
      const cat = i.node.categories.nodes;
      return _.find(cat, function(j) {
        return j.slug === el
      });
    });
  };
  const top_data = filterHelps('top');
  const office_data = filterHelps('office');
  const health_data = filterHelps('health');
  const hotel_data = filterHelps('hotel');
  const other_data = filterHelps('other');

  const Data = {
    "works": chunkHelps(works_data, 6),
    "office": chunkHelps(office_data, 6),
    "health": chunkHelps(health_data, 6),
    "hotel": chunkHelps(hotel_data, 6),
    "other": chunkHelps(other_data, 6),
  };

  const rendernewestWorks = () => {
    const worksEdges = top_data.slice(0, 3)
    const result = worksEdges.map((worksEdge) => {
      const { id, slug, project } = worksEdge.node
      return (
        <div className="swiper-slide" key={id}>
          <Link to={`/works/${slug}/`}>
            {project.worksCover ? (
              <GatsbyImage image={project.worksCover?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
            ) : (
              <GatsbyImage image={data.default?.childImageSharp?.gatsbyImageData} alt="" />
            )}
            <div className="lab lab-cn">
              <div className="addr">{project.worksLocation}</div>
              <div className="name">{project.worksTitleCn}</div>
            </div>
          </Link>
        </div>
      );
    })

    return result
  }


  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  
  const swiperInit = () => {
    new Swiper(".project-class-swiper .swiper-container", {
      slidesPerView: 1,
      speed: 400,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
    })
  }

  useEffect(() => {
    swiperInit()
    return () => {}
  }, [])
  return (
    <Layout>
      <SEO title="项目案例" />
      <div className={`mainContent`}>
        <Header
          activePage="works"
          color={{ bg: "transparent", text: "#fff" }}
        />
        <div className="banner" data-bg-color="transparent" data-color="#fff">
          <GatsbyImage image={data.banner?.childImageSharp?.gatsbyImageData} alt="" />
        </div>
        <div
          className="main-project js-viewport-animation"
          data-bg-color="#f5f5f5"
          data-color="#000"
        >
          <div className="wp1180">
            <div className="project">
              <div className="project-class project-class-swiper wow fadeIn">
                <div className="swiper-container">
                  <div className="swiper-wrapper">{rendernewestWorks()}</div>
                </div>
              </div>
              <div className="tab01 tab01-cn js-tabs" data-modulename="tab01">
                <div className="tab-nav">
                  <Tabs
                    className={`tab01-tit`}
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                  >
                    <Tab
                      label="全部"
                      className={`${tab01Item} tab01-item`}
                    />
                    <Tab
                      label="办公"
                      className={`${tab01Item} tab01-item`}
                    />
                    <Tab
                      label="健康"
                      className={`${tab01Item} tab01-item`}
                    />
                    <Tab
                      label="酒店"
                      className={`${tab01Item} tab01-item`}
                    />
                    <Tab
                      label="其他"
                      className={`${tab01Item} tab01-item`}
                    />
                  </Tabs>
                  <div className="tab-content">
                    <TabPanel value={value} index={0}>
                      <WorksList data={Data['works']} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <WorksList data={Data['office']} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <WorksList data={Data['health']} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <WorksList data={Data['hotel']} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <WorksList data={Data['other']} />
                    </TabPanel>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default WorksPage
